export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')];

export const server_loads = [0,3,4];

export const dictionary = {
	"/": [5],
	"/auth/login": [~6],
	"/auth/logout": [7],
	"/auth/register": [8],
	"/auth/reset-password": [9],
	"/book": [10],
	"/committee": [~11],
	"/committee/previous": [~12],
	"/faqs/book": [13,[2]],
	"/faqs/join": [14,[2]],
	"/faqs/members": [15,[2]],
	"/join": [16],
	"/mailinglists": [17],
	"/members": [~18,[3]],
	"/members/gigs": [~19,[3]],
	"/members/gigs/contacts/[contact_id=integer]": [~23,[3]],
	"/members/gigs/help": [24,[3]],
	"/members/gigs/signups": [~25,[3]],
	"/members/gigs/venues": [26,[3,4]],
	"/members/gigs/venues/[venue_id=integer]": [~27,[3,4]],
	"/members/gigs/[gig_id=integer]": [~20,[3]],
	"/members/gigs/[gig_id=integer]/edit-lineup": [~22,[3]],
	"/members/gigs/[gig_id=integer]/edit": [~21,[3]],
	"/members/list042": [~28,[3]],
	"/members/music": [~29,[3]],
	"/members/users": [30,[3]],
	"/members/users/[id=integer]": [~31,[3]],
	"/members/whoswho": [~32,[3]],
	"/session": [33]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};